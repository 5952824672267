import { createClaimsTable } from '../utils/claimUtils';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faLongArrowRight, faSignOut, faUser } from '@fortawesome/free-solid-svg-icons';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from "@azure/msal-browser"; 
import { b2cPolicies, protectedResources } from '../authConfig';
import { NavLink } from 'react-router-dom';
import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { useEffect, useState } from 'react';
import logo from '../assets/fridaylogo.png';

const Header = styled.div`
    height: 12%;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: #4d59e0;
    color: white;
    display:flex;
    justify-content: space-between;
    padding-left: 7%;
    padding-right: 7%;
    align-items: center;
    width: 100%;
`;

const AvatarImage = styled.img`
    vertical-align: middle;
    width: 50px;
    height: 50px;
    border-radius: 50%;
`;

const Footer = styled.div`
    position: absolute;
    height: 10%;
    border-top: 1px solid #e3e3e3;
    bottom: 0;
    display: flex;
    justify-content: center;
    background-color: white;
    width: 100%;
    align-items: center;
`;

const IconDiv = styled(FontAwesomeIcon)`
    margin-right: 5%;
    font-size: 20px;
    margin-left: 5%;
`;

const ItemsContainer = styled.div`
    height: 78%;
    overflow-y: auto;
    padding: 20px;
`;

const ItemsContainerLoading = styled.div`
    height: 78%;
    overflow-y: auto;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ItemDivHolder = styled.div`
    border: 1px solid #EFF1F8;
    border-radius: 10px;
    margin-bottom: 10px;
`

export const HomePageDisplay = (props) => {
    const { instance, inProgress } = useMsal();

    const [ssoData, setSsoData] = useState(null);

    const tokenClaims = createClaimsTable(props.idTokenClaims);

    const getFullName = () => {
        for (const [key, value] of Object.entries(tokenClaims)) {
            if (value[0] === "name") {
                return value[1];
            }
        }
        return null; // Return null if "name" is not found
    }

    const fullName = getFullName();

    const handleLogoutRedirect = () => {
        instance.logoutRedirect();
    };

    const handleProfileEdit = () => {
        if(inProgress === InteractionStatus.None){
           instance.acquireTokenRedirect(b2cPolicies.authorities.editProfile);
        }
    };

    const { error, execute, isLoading } = useFetchWithMsal({
        scopes: [
            ...protectedResources.ssoApi.scopes.read,
            ...protectedResources.ssoApi.scopes.admin,
        ]
    });

    useEffect(() => {
        if (!ssoData) {
            execute("GET", protectedResources.ssoApi.endpoint).then((response) => {
                setSsoData(response);
            });
        }
    }, [execute, ssoData]);

    const openAppUrl = (url) => {
        window.open(url, '_blank').focus();
    }

    return (
        <>
            <Header>
                <div>
                    <img src={logo} alt="" style={{ width: '70%', height: '100%' }}></img>
                </div>
                <div>
                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                        <AvatarImage src="https://www.w3schools.com/howto/img_avatar.png" alt="Avatar"></AvatarImage>
                    </div>
                    <div><span style={{ fontSize: '14px', display: 'block' }}>{fullName}</span></div>
                </div>
            </Header>
            {!isLoading && !error ? <ItemsContainer>
                {ssoData && ssoData.map((data, i) => (
                    <ItemDivHolder key={i} style={{ cursor: 'pointer' }} onClick={() => openAppUrl(data.signInUrl)}>
                        <div style={{ display: 'flex', padding: '16px' }}>
                            <AvatarImage src={data.icon} alt="Avatar"></AvatarImage>
                            <div style={{ display: 'flex', marginLeft: '5%', alignItems: 'center' }}>
                                <span style={{ fontWeight: '600' }}>{data.name}</span>
                            </div>
                        </div>
                        <div style={{ background: '#EFF1F7', color: '#4d59e0', display: 'flex', justifyContent: 'space-between', padding: '5px 16px', alignItems: 'center', fontSize: '12px' }}>
                            <div>
                                <span>Open Application</span>
                            </div>
                            <FontAwesomeIcon icon={faLongArrowRight}></FontAwesomeIcon>
                        </div>
                    </ItemDivHolder>
                ))}
            </ItemsContainer>: <ItemsContainerLoading>
                <span className="loader"></span>
            </ItemsContainerLoading>}
            <Footer>
                <NavLink to="/"><IconDiv icon={faHome} /></NavLink>
                {/* <IconDiv icon={faUser} onClick={handleProfileEdit}/> */}
                <IconDiv style={{ cursor: 'pointer' }} icon={faSignOut} onClick={handleLogoutRedirect}/>
            </Footer>
        </>
    );
};
 